import {
  PatchedProjectRequest,
  ProjectRequest,
  ProjectsApiListProjectsRequest,
} from "@sakiku/vstorage-api-client";
import { ProjectsApi } from "@sakiku/vstorage-api-client";

import { makeConfiguration } from "./configuration";

const makeProjectsClient = (accessToken: string) =>
  new ProjectsApi(makeConfiguration(accessToken));

export const createProject = async (
  accessToken: string,
  project: ProjectRequest
) => {
  const client = makeProjectsClient(accessToken);
  const { data } = await client.createProject({ projectRequest: project });
  return data;
};

export const updateProject = async (
  accessToken: string,
  slug: string,
  body: PatchedProjectRequest
) => {
  const client = makeProjectsClient(accessToken);
  const { data } = await client.patchProject({
    slug,
    patchedProjectRequest: body,
  });
  return data;
};

export const deleteProject = async (accessToken: string, slug: string) => {
  const client = makeProjectsClient(accessToken);
  const { data } = await client.deleteProject({ slug });
  return data;
};

export const getProjects = async (
  accessToken: string,
  requestParameters?: ProjectsApiListProjectsRequest
) => {
  const client = makeProjectsClient(accessToken);
  const { data } = await client.listProjects(requestParameters);
  return data;
};

export const getProject = async (accessToken: string, slug: string) => {
  const client = makeProjectsClient(accessToken);
  const { data } = await client.retrieveProject({ slug });
  return data;
};
