// import Header from "@sakiku/react-component/components/Header";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Header } from "@sakiku/react-component";
import Head from "next/head";
import { Fragment, ReactNode } from "react";

import SideBar from "@/components/SideBar";

export type BaseLayoutProps = {
  children: ReactNode;
  title: string;
  sidebar?: ReactNode;
};

const Root = styled.div`
  flexgrow: 1;
`;

export default function BaseLayout(props: BaseLayoutProps) {
  const { children, title, sidebar } = props;

  const pages = [
    { name: "ダッシュボード", link: "/" },
    { name: "プロジェクト", link: "/" },
  ];

  return (
    <Fragment>
      <Root>
        <Head>
          <title>{title} | V-storage</title>
        </Head>
        <div>
          <Header pages={pages} />
          {sidebar ? (
            <Box sx={{ display: "flex" }}>
              <SideBar>{sidebar}</SideBar>
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                {children}
              </Box>
            </Box>
          ) : (
            <Box sx={{ pt: 9 }}>
              <main>{children}</main>
            </Box>
          )}
        </div>
      </Root>
    </Fragment>
  );
}
