import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import { ReactNode } from "react";

const drawerWidth = 240;

type SideBarProps = {
  children: ReactNode;
};

export default function SideBar(props: SideBarProps) {
  const { children } = props;
  return (
    <Drawer
      variant="permanent"
      sx={{
        zIndex: 0,
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>{children}</Box>
    </Drawer>
  );
}
