import { ProjectsApiListProjectsRequest } from "@sakiku/vstorage-api-client";
import { useQuery } from "react-query";

import { getProject, getProjects } from "@/api/projects";

import { useAccessToken } from "./auth0";

export function useProjectList(
  requestParameters?: ProjectsApiListProjectsRequest
) {
  const accessToken = useAccessToken({
    audience: "https://v-storage-django-api/",
  });
  const queryKey = ["projectList", requestParameters];
  return useQuery(
    queryKey,
    () => {
      return getProjects(accessToken || "", requestParameters);
    },
    { enabled: !!accessToken }
  );
}

export function useProject(slug?: string | string[]) {
  const accessToken = useAccessToken({
    audience: "https://v-storage-django-api/",
  });
  const queryKey = ["projectDetail", slug];
  return useQuery(
    queryKey,
    () => {
      if (!accessToken || !slug) return undefined;
      if (typeof slug === "string") return getProject(accessToken, slug);
      return undefined;
    },
    { enabled: !!accessToken && !!slug }
  );
}
