import {
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  useAuth0,
} from "@auth0/auth0-react";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

export type GetTokenOptions =
  | GetTokenSilentlyOptions
  | GetTokenWithPopupOptions;

export const useMyAuth0 = () => {
  const {
    // Auth state:
    error,
    isAuthenticated,
    isLoading,
    user,
    // Auth methods:
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithRedirect,
    loginWithPopup,
    logout: auth0Logout,
  } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  // エラー発生時にSnackbarを表示
  useEffect(() => {
    if (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [enqueueSnackbar, error]);

  // `getAccessTokenSilently`と`getAccessTokenWithPopup`のWrapper
  const getAccessToken = (
    options?: GetTokenOptions,
    withPopup?: boolean
  ): Promise<string> => {
    const defaultOptions: GetTokenOptions = {
      audience: process.env.NEXT_PUBLIC_API_AUDIENCE,
    };
    const opt = typeof options !== "undefined" ? options : defaultOptions;
    const promise = withPopup
      ? getAccessTokenWithPopup(opt)
      : getAccessTokenSilently(opt);
    return promise
      .then((value) => value)
      .catch((err) => {
        // error: "consent_required"
        // error_description: "Consent required"
        console.log(err);
        // const errorMsg = `Faild get access token: ${err.error_description}`;
        // enqueueSnackbar(errorMsg, { variant: "error" });
        if (err.error === "consent_required" && !withPopup) {
          return getAccessToken(options, true);
        }
        throw err;
      });
  };

  return {
    // Auth state:
    error,
    isAuthenticated,
    isLoading,
    user,
    org_id: (user?.org_id as string) || undefined,
    // Auth methods:
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    getIdTokenClaims,
    loginWithRedirect,
    loginWithPopup,
    logout: () => auth0Logout({ returnTo: window.location.origin }),
    getAccessToken,
  };
};

export const useAccessToken = (options?: GetTokenOptions) => {
  const { getAccessToken } = useMyAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const [accessToken, setAccessToken] = useState<string>("");

  useEffect(() => {
    getAccessToken(options, false)
      .then((value) => setAccessToken(value))
      .catch((error) => {
        console.log(error);
        const errorMsg = `Faild get access token: ${error.error_description}`;
        enqueueSnackbar(errorMsg, { variant: "error" });
      });
  }, [enqueueSnackbar, getAccessToken, options]);

  return accessToken;
};

type DecodedJwtPayload = JwtPayload & {
  scope?: string;
  permissions?: string[];
};

export const useScopes = (options?: GetTokenOptions) => {
  const accessToken = useAccessToken(options);
  const [scopes, setScopes] = useState<string[]>();
  useEffect(() => {
    if (accessToken) {
      const decoded = jwt_decode<DecodedJwtPayload>(accessToken);
      console.log(decoded);
      setScopes(decoded.scope?.split(" "));
    }
  }, [accessToken]);
  return { scopes, isLoading: typeof scopes === "undefined" };
};

export const usePermissions = (options?: GetTokenOptions) => {
  const accessToken = useAccessToken(options);
  const [permissions, setPermissions] = useState<string[]>();
  useEffect(() => {
    if (accessToken) {
      const decoded = jwt_decode<DecodedJwtPayload>(accessToken);
      console.log(decoded);
      setPermissions(decoded?.permissions);
    }
  }, [accessToken]);
  return { permissions, isLoading: typeof permissions === "undefined" };
};

export const useScopesWithPermissions = (options?: GetTokenOptions) => {
  const { scopes, isLoading: isScopesLoading } = useScopes(options);
  const { permissions, isLoading: isPermissionsLoading } =
    usePermissions(options);
  const scopesWithPermissions: string[] = (scopes || []).concat(
    permissions || []
  );
  return {
    scopes: Array.from(new Set(scopesWithPermissions)),
    isLoading: isScopesLoading && isPermissionsLoading
  };
};

